import {
  Box,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Select,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { UserContext } from "../App";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ReactComponent as Cross } from "../icons/whiteCross.svg";
import { ReactComponent as Delete } from "../icons/dustbin.svg";
import AppBar from "@mui/material/AppBar";
import { Helmet } from "react-helmet";

import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

import Slide from "@mui/material/Slide";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import PDFView from "./PDFView.js";
import "./quotationCreation.css";
import "./QueryDetails.css";
import RowProduct from "./AddedProductRow.js";
import PaymentTermsTable from "./PaymentTermsTable.js";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ConfirmationPopUp from "./ConfirmationPopUp.js";
import PreviousQuotationImport from "./PreviousQuotationImport";
import QuoteGeneratedPopup from "./QuoteGeneratedPopup.js";

const QueryDetails = ({ setProductCount, queryId, quotationId }) => {
  const [loading, setIsloading] = useState(false);
  const [uploadPdfLoader, setUploadPdfLoader] = useState(false);

  const [queryDetailsData, setQueryDetailsData] = useState({});
  const { user } = useContext(UserContext);
  const [openremarkPopUp, setOpenRemarkPopUp] = useState(false);
  const [openCloseremarkPopUp, setOpenCloseRemarkPopUp] = useState(false);
  const [openSendToPricingremarkPopUp, setOpenSendToPricingRemarkPopUp] =
    useState(false);

  const [vendorsList, setVendorsList] = useState([]);

  const storedItemsString = localStorage.getItem("selectedItems");
  const storedItems = storedItemsString ? JSON.parse(storedItemsString) : [];

  const [storedItemsChecklist, setStoredItemsChecklist] = useState(
    storedItems || []
  );
  const [quoteConfirmation, setQuoteConfirmation] = useState(false);
  const [reopenToCategoryConfirmation, setReopenToCategoryConfirmation] =
    useState(false);
  const [sendToPricingConfirmation, setSendToPricingConfirmation] =
    useState(false);
  const [remark, setRemark] = useState("");
  const [remark2, setRemark2] = useState("");
  const [quotations, setQuotations] = useState([]);
  const [showPrevDialog, setshowPrevDialog] = useState(false);
  const [openRow, setOpenRow] = useState(null);
  const [quoteGeneratedPopup, setQuoteGeneratedPopup] = useState(false);
  const [quoteLink, setQuoteLink] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState(''); // Initialize deliveryCharges state
  const [isDelivery, setisDelivery] = useState(false);

  const handleCheckboxChange = (event) => {
    setisDelivery(event.target.checked);
  };

  const handleInputChange = (event) => {
    setDeliveryCharges(event.target.value);
  };

  useEffect(() => {
    if (!isDelivery) {
      setDeliveryCharges('0');
    }
  }, [isDelivery]);

  const handleRowClick = (rowSku) => {
    setOpenRow((prevRowSku) => (prevRowSku === rowSku ? null : rowSku));
  };

  const [amount, setAmount] = useState(
    storedItemsChecklist.reduce((total, row) => {
      const amount = row.Amount !== "" ? parseFloat(row.Amount) : 0;
      return total + amount;
    }, 0)
  );

  const [totalCostPrice, setTotalCostPrice] = useState(
    storedItemsChecklist.reduce(
      (total, row) => total + parseFloat(row.quantity * row.cpegst),
      0
    )
  );
  const [totalspegstPrice, setTotalspegstPrice] = useState(
    storedItemsChecklist.reduce(
      (total, row) => total + parseFloat(row.quantity * row.spegst),
      0
    )
  );
  const [totalspigstPrice, setTotalspigstPrice] = useState(
    storedItemsChecklist.reduce(
      (total, row) => total + parseFloat(row.quantity * row.spigst),
      0
    )
  );

  const [totalquantity, setTotalquantity] = useState(
    storedItemsChecklist.reduce(
      (total, row) => total + parseFloat(row.quantity),
      0
    )
  );

  const [totalMarginByValue, setTotalMarginByValue] = useState(
    storedItemsChecklist.reduce(
      (total, row) =>
        total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
      0
    )
  );

  const cachedDataGlobal = JSON.parse(
    localStorage.getItem(`queryDetailsData_${queryId}`)
  );

  const [selectedDiscountType, setSelectedDiscountType] = useState("None");
  const [discountInPercent, setDiscountInPercent] = useState("0");
  const [discountInAmount, setDiscountInAmount] = useState("0");
  const [discountedTotalAmount, setDiscountedTotalAmount] = useState(
    `${((100 - discountInPercent) * amount) / 100}`
  );

  const [attachmentTitle, setAttachmentTitle] = useState("");
  const [attachmentFile, setAttachmentFile] = useState("");
  const [prevuploadedAttachments, setPrevUploadedAttachments] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [paymentTermsCondition, setPaymentTermsCondition] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [warrantyDuration, setWarrantyDuration] = useState([]);
  const [pricingWarmth, setPricingWarmth] = useState("");

  const navigate = useNavigate();

  const tableHeader = [
    "Query ID",
    "Date",
    "Email",
    "Product",
    "Name of Establishment",
    "Name of POC",
    "Urgent Query?",
    "City",
    "Warmth",
    "Status",
  ];

  const priceListHeader = [
    "Product Details",
    "Partner Name",
    "Warranty",
    "Cost Per Unit(Excl GST)",
    "GST%",
    "Market Selling Price(Excl GST)",
    "Minimum Selling Price(Excl GST)",
    "MRP",
    "Margin(in %)",
    "Selling Price(Excl GST)",
    "Selling Price(Incl GST)",
    "Quantity",
    "Amount",
    "Delete from Quote",
  ];

  const filteredHeaders = priceListHeader.filter((header) => {
    return user && (user.role === "superuser" || user.role === "pricing")
      ? header !== "Partner Name"
      : user && user.role === "sales"
      ? header !== "Cost Per Unit(Excl GST)" &&
        header !== "Margin(in %)" &&
        header !== "Partner Name"
      : header;
  });

  const [importPopUp, setImportPopUp] = useState(false);
  const handleOpenImportQuotationPopUp = () => {
    setImportPopUp(true);
  };

  const warrantyDurationOptions = warrantyDuration.map((duration) => ({
    value: duration,
    label: duration,
  }));

  const [paymentrows, setPaymentRows] = useState(
    cachedDataGlobal?.paymentTerms?.length > 1
      ? cachedDataGlobal.paymentTerms
      : [
          {
            paymentTermstype: "Advance",
            duration: "",
            paymentTermsAmount: amount,
            paymentTermsPercentage: 100,
          },
          {
            paymentTermstype: "On Delivery",
            duration: "",
            paymentTermsAmount: 0,
            paymentTermsPercentage: 0,
          },
          {
            paymentTermstype: "On Installation",
            duration: "",
            paymentTermsAmount: 0,
            paymentTermsPercentage: 0,
          },
          {
            paymentTermstype: "Credit(In Days)",
            duration: "",
            paymentTermsAmount: 0,
            paymentTermsPercentage: 0,
          },
          {
            paymentTermstype: "EMI(In Months)",
            duration: "",
            paymentTermsAmount: 0,
            paymentTermsPercentage: 0,
          },
        ]
  );

  const handleopenpreview = () => {
    updateQuotations();

    setshowPrevDialog(true);
  };

  const updateQuotations = () => {
    setQuotations([
      {
        queryDetails: queryDetailsData,

        itemDetails: storedItemsChecklist,

        paymentTerms: paymentrows,

        totalAmount: amount,

        discountInRupees: discountInAmount,

        discountInPercent: discountInPercent,

        discountedAmount: discountedTotalAmount,

        attachmentDetails: prevuploadedAttachments,
        isPaymentTable: isChecked,
        deliveryCharges: deliveryCharges,
      },
    ]);
  };

  const getQueryData = async () => {
    setIsloading(true);
    try {
      const cachedData = JSON.parse(
        localStorage.getItem(`queryDetailsData_${queryId}`)
      );

      if (cachedData) {
        setQueryDetailsData(cachedData);
        setStoredItemsChecklist(
          cachedData.itemDetails ? cachedData.itemDetails : []
        );
        localStorage.setItem(
          "selectedItems",
          JSON.stringify(cachedData.itemDetails ? cachedData.itemDetails : [])
        );
        setPrevUploadedAttachments(
          cachedData.attachmentDetails ? cachedData.attachmentDetails : []
        );
        setAmount(
          cachedData.itemDetails?.reduce((total, row) => {
            const amount = row.Amount !== "" ? parseFloat(row.Amount) : 0;
            return total + amount;
          }, 0)
        );

        setTotalCostPrice(
          cachedData.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.cpegst),
            0
          )
        );

        setTotalspegstPrice(
          cachedData.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.spegst),
            0
          )
        );
        setTotalspigstPrice(
          cachedData.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.spigst),
            0
          )
        );
        setTotalquantity(
          cachedData.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity),
            0
          )
        );

        setTotalMarginByValue(
          cachedData.itemDetails?.reduce(
            (total, row) =>
              total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
            0
          )
        );

        cachedData.paymentTerms?.length > 1
          ? setPaymentRows(cachedData.paymentTerms)
          : setPaymentRows(paymentrows);

        // cachedData.discountInPercent
        //   ? setDiscountInPercent(cachedData.discountInPercent)
        //   : setDiscountInPercent("0");

        // cachedData.discountInPercent
        //   ? setDiscountInAmount(
        //       `${
        //         (cachedData.discountInPercent *
        //           cachedData.itemDetails?.reduce(
        //             (total, row) => total + parseFloat(row.Amount),
        //             0
        //           )) /
        //         100
        //       }`
        //     )
        //   : setDiscountInAmount("0");
        // cachedData.discountInPercent
        //   ? setDiscountedTotalAmount(
        //       `${
        //         ((100 - cachedData.discountInPercent) *
        //           cachedData.itemDetails?.reduce(
        //             (total, row) => total + parseFloat(row.Amount),
        //             0
        //           )) /
        //         100
        //       }`
        //     )
        //   : setDiscountedTotalAmount(
        //       cachedData.itemDetails?.reduce((total, row) => {
        //         const amount = row.Amount !== "" ? parseFloat(row.Amount) : 0;
        //         return total + amount;
        //       }, 0)
        //     );
      } else if (quotationId) {
        const response = await fetch(
          `/quotegen/quotationCreation/${queryId}/${quotationId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { lsqdata, quotationData } = await response.json();

        localStorage.setItem(
          "selectedItems",
          JSON.stringify(quotationData.itemDetails)
        );

        setStoredItemsChecklist(quotationData.itemDetails);

        localStorage.setItem(
          `queryDetailsData_${queryId}`,
          JSON.stringify(lsqdata)
        );
        setQueryDetailsData(lsqdata);

        setAmount(
          quotationData.itemDetails?.reduce((total, row) => {
            const amount = row.Amount !== "" ? parseFloat(row.Amount) : 0;
            return total + amount;
          }, 0)
        );

        setTotalCostPrice(
          quotationData.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.cpegst),
            0
          )
        );

        setTotalspegstPrice(
          quotationData.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.spegst),
            0
          )
        );
        setTotalspigstPrice(
          quotationData.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.spigst),
            0
          )
        );
        setTotalquantity(
          quotationData.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity),
            0
          )
        );

        setTotalMarginByValue(
          quotationData.itemDetails?.reduce(
            (total, row) =>
              total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
            0
          )
        );
        setPaymentRows(quotationData.paymentTerms);
        setDiscountInAmount(`${quotationData.discountInRupees}`);
        setDiscountInPercent(`${quotationData.discountInPercent}`);
        setDiscountedTotalAmount(`${quotationData.discountedAmount}`);
        setPrevUploadedAttachments(quotationData.attachmentDetails);
      } else {
        const response = await fetch(`/quotegen/quotationCreation/${queryId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const { data } = await response.json();

        if (data.status === "alreadyShared") {
          window.location.href = "/queryDetailsLsq";
          return;
        }

        if (data.itemDetails.length > 0 && data.fromCategory) {
          localStorage.setItem(
            "selectedItems",
            JSON.stringify(data.itemDetails)
          );

          setStoredItemsChecklist(data.itemDetails);
        }

        if (
          data.itemDetails.length > 0 &&
          data.status === "pendingOnCategory"
        ) {
          localStorage.setItem(
            "selectedItems",
            JSON.stringify(data.itemDetails)
          );
          setStoredItemsChecklist(data.itemDetails);
        }
        localStorage.setItem(
          `queryDetailsData_${queryId}`,
          JSON.stringify(data)
        );

        setQueryDetailsData(data);

        setAmount(
          data.itemDetails?.reduce((total, row) => {
            const amount = row.Amount !== "" ? parseFloat(row.Amount) : 0;
            return total + amount;
          }, 0)
        );

        setTotalCostPrice(
          data.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.cpegst),
            0
          )
        );

        setTotalspegstPrice(
          data.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.spegst),
            0
          )
        );
        setTotalspigstPrice(
          data.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity * row.spigst),
            0
          )
        );
        setTotalquantity(
          data.itemDetails?.reduce(
            (total, row) => total + parseFloat(row.quantity),
            0
          )
        );

        setTotalMarginByValue(
          data.itemDetails?.reduce(
            (total, row) =>
              total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
            0
          )
        );

        data.paymentTerms?.length > 1
          ? setPaymentRows(data.paymentTerms)
          : setPaymentRows(paymentrows);

        data.discountInRupees
          ? setDiscountInAmount(data.discountInRupees)
          : setDiscountInAmount("0");
        data.discountInPercent
          ? setDiscountInPercent(data.discountInPercent)
          : setDiscountInPercent("0");
        data.discountedAmount
          ? setDiscountedTotalAmount(data.discountedAmount)
          : setDiscountedTotalAmount("0");
        setPrevUploadedAttachments(data.attachmentDetails);
      }
    } catch (error) {
      console.error("Error fetching query data:", error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getQueryData();
    getVendors();
    getWarranties();
    window.scrollTo(0, 0);
  }, []);

  const getWarranties = async () => {
    const response = await fetch("/quotegen/getWarranties");
    const data = await response.json();
    setWarrantyDuration(data.warranties);
  };

  const handleOpenQuotePopup = () => {
    setQuoteGeneratedPopup(true);
  };

  const handleCloseQuotePopup = () => {
    navigate("/queryDetailsLsq");
    setQuoteGeneratedPopup(false);
  };

  // generate quotation

  const createQuotation = async () => {
    if (storedItemsChecklist.some((item) => item.warranty_duration === "")) {
      toast.error("Select Warranty in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (
      storedItemsChecklist.some(
        (item) =>
          item.quantity === "" ||
          Number(item.quantity) < 1 ||
          !Number.isInteger(Number(item.quantity))
      )
    ) {
      toast.error("Enter Valid quantity", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (
      storedItemsChecklist.some(
        (item) => item.margin === "" || Number(item.margin) >= 100
      )
    ) {
      toast.error("Enter Valid Margin in all products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      storedItemsChecklist.some(
        (item) => item.spegst === "" || Number(item.spegst) < 0
      )
    ) {
      toast.error("Enter Valid Selling Price in all products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let fromCategory;
    if (queryDetailsData.fromCategory) {
      fromCategory = true;
    } else {
      fromCategory = false;
    }
    let retryCount = 0;
    const maxRetries = 2;
    let quotationResponse;
    try {
      setIsloading(true);

      //post wala
      const response = await fetch(`/quotegen/createQuotation/${queryId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          queryDetailsData: queryDetailsData,
          storedItemsChecklist: storedItemsChecklist,
          fromCategory: fromCategory,
          paymentTerms: paymentrows,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: prevuploadedAttachments,
          isChecked: isChecked,
          pricingWarmth: pricingWarmth,
          deliveryCharges: deliveryCharges,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      //put wala
      const response2 = await fetch(`/quotegen/quotationCreation/${queryId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updatedStatus: "alreadyShared",
          queryDetailsData: queryDetailsData,
          storedItemsChecklist: storedItemsChecklist,
          fromCategory: fromCategory,
          paymentTerms: paymentrows,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: prevuploadedAttachments,
          quotationId: data.quotationId,
        }),
      });
      const { success } = await response2.json();

      if (data.success && success) {
        await handleSaveDraft();
        localStorage.removeItem("selectedItems");

        localStorage.removeItem(`queryDetailsData_${queryId}`);

        const quotationLink = window.location.href.includes("admin.zoplar.com")
          ? `quote.zoplar.com`
          : `testquote.zoplar.com`;

        handleOpenQuotePopup();
        setQuoteLink(
          `https://${quotationLink}/quotation/${data.encryptedText}`
        );

        // window.open(
        //   `http://quote.zoplar.com/quotation/${data.encryptedText}`,
        //   "_blank"
        // );
        setStoredItemsChecklist([]);
        setProductCount(0);

        // navigate("/queryDetailsLsq");
      } else {
        console.log("Try Again");
      }
    } catch (error) {
      toast.error("Error Uploading Attachments", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setUploadPdfLoader(false);
      setIsloading(false);
      setQuoteConfirmation(false);
    }
  };

  const handleProductDelete = (row) => {
    const updatedItems = storedItemsChecklist.filter(
      (item) => item.sku !== row.sku
    );

    setAmount(
      updatedItems.reduce((total, row) => total + parseFloat(row.Amount), 0)
    );
    setTotalCostPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.cpegst),
        0
      )
    );
    setTotalspegstPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.spegst),
        0
      )
    );
    setTotalspigstPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.spigst),
        0
      )
    );
    setTotalquantity(
      updatedItems.reduce((total, row) => total + parseFloat(row.quantity), 0)
    );
    setTotalMarginByValue(
      updatedItems.reduce(
        (total, row) =>
          total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
        0
      )
    );
    setDiscountInAmount(
      `${
        (discountInPercent *
          updatedItems.reduce(
            (total, row) => total + parseFloat(row.Amount),
            0
          )) /
        100
      }`
    );
    const updatedDiscountedAmount = `${
      ((100 - discountInPercent) *
        updatedItems.reduce(
          (total, row) => total + parseFloat(row.Amount),
          0
        )) /
      100
    }`;
    setDiscountedTotalAmount(updatedDiscountedAmount);

    setStoredItemsChecklist(updatedItems);
    setProductCount(updatedItems.length);
    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
    if (queryId) {
      const cachedData = JSON.parse(
        localStorage.getItem(`queryDetailsData_${queryId}`)
      );
      cachedData.itemDetails = updatedItems;
      cachedData.totalAmount = `${updatedItems.reduce(
        (total, row) => total + parseFloat(row.Amount),
        0
      )}`;
      // cachedData.discountedAmount = `${
      //   ((100 - discountInPercent) *
      //     updatedItems.reduce(
      //       (total, row) => total + parseFloat(row.Amount),
      //       0
      //     )) /
      //   100
      // }`;

      if (user && user.role === "sales") {
        const updatedPaymentTerms = paymentrows.map((prevRow) =>
          prevRow.paymentTermsPercentage !== 0
            ? {
                ...prevRow,
                paymentTermsAmount:
                  (updatedDiscountedAmount * prevRow.paymentTermsPercentage) /
                  100,
                paymentTermsPercentage: prevRow.paymentTermsPercentage,
              }
            : prevRow
        );

        setPaymentRows(updatedPaymentTerms);
      }
      localStorage.setItem(
        `queryDetailsData_${queryId}`,
        JSON.stringify(cachedData)
      );
    }
  };

  const handleValueEdited = (row, updatedKey, updatedValue) => {
    const updatedItems = storedItemsChecklist.map((item) => {
      if (item.sku === row.sku) {
        // Update the specific key
        if (updatedKey === "spegst") {
          item.margin = `${(1 - item.cpegst / updatedValue) * 100}%`;
          item.spegst = updatedValue;
        } else {
          item[updatedKey] = updatedValue;
          item.spegst = item.cpegst / (1 - parseFloat(item.margin) / 100);
        }

        if (updatedKey === "description") {
          toast.success(`Description Edited for SKU ${row.sku}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        item.Amount =
          (item.cpegst * item.quantity * (1 + parseFloat(item.gst) / 100)) /
          (1 - parseFloat(item.margin) / 100);

        item.spigst =
          (item.cpegst * (1 + parseFloat(item.gst) / 100)) /
          (1 - parseFloat(item.margin) / 100);
      }
      return item;
    });

    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
    const getDetails = JSON.parse(
      localStorage.getItem(`queryDetailsData_${queryId}`)
    );
    getDetails["itemDetails"] = updatedItems;
    localStorage.setItem(
      `queryDetailsData_${queryId}`,
      JSON.stringify(getDetails)
    );
    setAmount(
      updatedItems.reduce((total, row) => total + parseFloat(row.Amount), 0)
    );
    setTotalCostPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.cpegst),
        0
      )
    );
    setTotalspegstPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.spegst),
        0
      )
    );
    setTotalspigstPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.spigst),
        0
      )
    );
    setTotalquantity(
      updatedItems.reduce((total, row) => total + parseFloat(row.quantity), 0)
    );
    setTotalMarginByValue(
      updatedItems.reduce(
        (total, row) =>
          total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
        0
      )
    );
    setDiscountInAmount(
      `${
        (discountInPercent *
          updatedItems.reduce(
            (total, row) => total + parseFloat(row.Amount),
            0
          )) /
        100
      }`
    );

    const updatedDiscountedAmount = `${
      ((100 - discountInPercent) *
        updatedItems.reduce(
          (total, row) => total + parseFloat(row.Amount),
          0
        )) /
      100
    }`;
    setDiscountedTotalAmount(updatedDiscountedAmount);

    if (queryId) {
      const cachedData = JSON.parse(
        localStorage.getItem(`queryDetailsData_${queryId}`)
      );
      cachedData.itemDetails = updatedItems;
      cachedData.totalAmount = updatedItems.reduce(
        (total, row) => total + parseFloat(row.Amount),
        0
      );
      // cachedData.discountedAmount = `${
      //   ((100 - discountInPercent) *
      //     updatedItems.reduce(
      //       (total, row) => total + parseFloat(row.Amount),
      //       0
      //     )) /
      //   100
      // }`;

      if (user && user.role === "sales") {
        const updatedPaymentTerms = paymentrows.map((prevRow) =>
          prevRow.paymentTermsPercentage !== 0
            ? {
                ...prevRow,
                paymentTermsAmount:
                  (updatedDiscountedAmount * prevRow.paymentTermsPercentage) /
                  100,
                paymentTermsPercentage: prevRow.paymentTermsPercentage,
              }
            : prevRow
        );

        setPaymentRows(updatedPaymentTerms);
      }
      localStorage.setItem(
        `queryDetailsData_${queryId}`,
        JSON.stringify(cachedData)
      );
    }
  };

  const getVendors = async () => {
    const response = await fetch(`/quotegen/vendors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { success, data: vendorData } = await response.json();

    if (success) {
      const vendorNamesArray = vendorData.map((vendor) => ({
        value: vendor.name,
        label: vendor.name,
        prospect_id: vendor.prospect_id,
      }));
      setVendorsList(vendorNamesArray);
    }
  };

  const handleValueEditedOfQuery = (updatedKey, updatedValue) => {
    setQueryDetailsData((prevQueryDetailsData) => ({
      ...prevQueryDetailsData,
      [updatedKey]: updatedValue,
    }));

    const getDetails = JSON.parse(
      localStorage.getItem(`queryDetailsData_${queryId}`)
    );
    getDetails[updatedKey] = updatedValue;
    localStorage.setItem(
      `queryDetailsData_${queryId}`,
      JSON.stringify(getDetails)
    );
  };

  const handleSentToPricingRemarkPopUp = () => {
    if (storedItemsChecklist.some((item) => item.cpegst === "")) {
      toast.error("Enter cost price of all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (storedItemsChecklist.some((item) => item.warranty_duration === "")) {
      toast.error("Select Warranty in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (storedItemsChecklist.some((item) => item.partner_name === "")) {
      toast.error("Select Partner in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (
      storedItemsChecklist.some(
        (item) =>
          item.quantity === "" ||
          Number(item.quantity) < 1 ||
          !Number.isInteger(Number(item.quantity))
      )
    ) {
      toast.error("Enter Valid quantity", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setOpenSendToPricingRemarkPopUp(true);
  };

  const handleSendToPricing = async (remarks) => {
    setOpenSendToPricingRemarkPopUp(false);
    if (storedItemsChecklist.some((item) => item.cpegst === "")) {
      toast.error("Enter cost price of all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (storedItemsChecklist.some((item) => item.warranty_duration === "")) {
      toast.error("Select Warranty in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (storedItemsChecklist.some((item) => item.partner_name === "")) {
      toast.error("Select Partner in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (
      storedItemsChecklist.some(
        (item) =>
          item.quantity === "" ||
          Number(item.quantity) < 1 ||
          !Number.isInteger(Number(item.quantity))
      )
    ) {
      toast.error("Enter Valid quantity", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    let retryCount = 0;
    const maxRetries = 2;
    let quotationResponse;
    let attachmentUploaded = [];
    try {
      setIsloading(true);

      const newrow = paymentrows.map((prevRow) =>
        prevRow.paymentTermsPercentage !== 0
          ? {
              ...prevRow,
              paymentTermsAmount:
                (discountedTotalAmount * prevRow.paymentTermsPercentage) / 100,
              paymentTermsPercentage: prevRow.paymentTermsPercentage,
            }
          : prevRow
      );
      const response = await fetch(`/quotegen/quotationCreation/${queryId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updatedStatus: "pendingOnPricing",
          storedItemsChecklist: storedItemsChecklist,
          paymentTerms: newrow,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: prevuploadedAttachments,
          sendToPricing: true,
          remarksForPricing: remarks,
        }),
      });
      const { success } = await response.json();

      if (success) {
        await handleSaveDraft();
        localStorage.setItem("selectedItems", []);
        localStorage.removeItem(`queryDetailsData_${queryId}`);

        setStoredItemsChecklist([]);
        setProductCount(0);
        navigate("/queryDetailsLsq");
      } else {
        console.log("Try Again");
      }
    } catch (error) {
      toast.error("Error Uploading PDF", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setUploadPdfLoader(false);
      setIsloading(false);
      setSendToPricingConfirmation(false);
    }
  };

  const handleOpenRemarkPopUp = () => {
    setOpenRemarkPopUp(true);
  };

  const handleSendToCategory = async (remarks) => {
    setOpenRemarkPopUp(false);
    let quotationResponse = {};
    let attachmentUploaded = [];
    let retryCount = 0;
    const maxRetries = 2;

    try {
      setIsloading(true);
      const response = await fetch(`/quotegen/quotationCreation/${queryId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updatedStatus: "pendingOnCategory",
          storedItemsChecklist: storedItemsChecklist,
          remarksForCategory: remarks,
          paymentTerms: paymentrows,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: prevuploadedAttachments,
          queryDetailsData: queryDetailsData,
          reopenToCategory: true,
        }),
      });
      const { success } = await response.json();

      if (success) {
        await handleSaveDraft();
        localStorage.setItem("selectedItems", []);
        localStorage.removeItem(`queryDetailsData_${queryId}`);
        setStoredItemsChecklist([]);
        setProductCount(0);
        navigate("/queryDetailsLsq");
      } else {
        console.log("Try Again");
      }
    } catch (error) {
      toast.error("Error Uploading PDF", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsloading(false);

      setOpenRemarkPopUp(false);
    }
  };

  const handleCloseQuery = async (remarksClose) => {
    setOpenCloseRemarkPopUp(false);
    try {
      setIsloading(true);
      const response = await fetch(`/quotegen/quotationCreation/${queryId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updatedStatus: "cannotProcure",
          closedRemarks: remarksClose,
        }),
      });
      const { success } = await response.json();

      if (success) {
        await handleSaveDraft();
        localStorage.setItem("selectedItems", []);
        localStorage.removeItem(`queryDetailsData_${queryId}`);
        setStoredItemsChecklist([]);
        setProductCount(0);
        navigate("/queryDetailsLsq");
      } else {
        console.log("Try Again");
      }
    } catch (error) {
      toast.error("Error Uploading PDF", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsloading(false);

      setOpenCloseRemarkPopUp(false);
    }
  };

  const changeChachedDiscountValue = (
    discountTotalVal,
    discountPrice,
    discoutCent
  ) => {
    const setQueryDetails = JSON.parse(
      localStorage.getItem(`queryDetailsData_${queryId}`)
    );

    setQueryDetails.discountedAmount = discountTotalVal;
    setQueryDetails.discountInRupees = discountPrice;
    setQueryDetails.discountInPercent = discoutCent;

    localStorage.setItem(
      `queryDetailsData_${queryId}`,
      JSON.stringify(setQueryDetails)
    );
  };

  const handleChangeDiscountAmount = (discountType) => {
    if (discountType === "amount") {
      if (discountInAmount > amount) {
        toast.error("discounted amount greater than amount", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDiscountedTotalAmount(`${amount}`);
        setDiscountInPercent(`0`);
        setDiscountInAmount(`0`);
        return;
      }

      const val1 = `${amount - discountInAmount}`;
      const val2 = `${(discountInAmount / amount) * 100}`;
      setDiscountedTotalAmount(val1);
      setDiscountInPercent(val2);
      changeChachedDiscountValue(val1, discountInAmount, val2);
    }
    if (discountType === "percent") {
      if (discountInPercent > 100) {
        toast.error("discounted percentage greater than 100", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setDiscountedTotalAmount(`${amount}`);
        setDiscountInPercent(`0`);
        setDiscountInAmount(`0`);
        return;
      }
      const val1 = `${((100 - discountInPercent) * amount) / 100}`;
      const val2 = `${(discountInPercent * amount) / 100}`;
      setDiscountedTotalAmount(val1);
      setDiscountInAmount(val2);
      changeChachedDiscountValue(val1, val2, discountInPercent);
    }
  };

  const handleSubmitAttachment = async (e) => {
    if (!attachmentTitle) {
      alert("Please provide a title.");
      return;
    }

    if (!attachmentFile) {
      alert("Please select a file.");
      return;
    }

    if (attachmentFile.size > 10 * 1024 * 1024) {
      alert("File size exceeds 10MB limit.");
      return;
    }

    let retryCount = 0;
    const maxRetries = 2;
    let quotationResponse;
    setUploadPdfLoader(true);
    while (retryCount <= maxRetries) {
      try {
        const formData = new FormData();
        formData.append("file", attachmentFile);
        formData.append("title", attachmentTitle);
        formData.append("queryId", queryId);
        const responseAttachment = await fetch("/quotegen/uploadAttachments", {
          method: "POST",
          body: formData,
        });

        quotationResponse = await responseAttachment.json();
        if (quotationResponse.success) {
          setUploadPdfLoader(false);
          setPrevUploadedAttachments((prevAttachments) => [
            ...prevAttachments,
            quotationResponse.attachmentDetails,
          ]);

          const getDetails = JSON.parse(
            localStorage.getItem(`queryDetailsData_${queryId}`)
          );
          getDetails["attachmentDetails"].push(
            quotationResponse.attachmentDetails
          );
          localStorage.setItem(
            `queryDetailsData_${queryId}`,
            JSON.stringify(getDetails)
          );
          toast.success(" Attachment Uploaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const chooseFileAttachment = document.getElementById(
            "choose-file-attachment"
          );
          chooseFileAttachment.value = null;
          break;
        }
        retryCount++;
      } catch (error) {
        console.error("Error uploading attachments:", error);
        if (retryCount <= maxRetries) {
          retryCount++;
          continue;
        } else {
          toast.error("Error Uploading Attachments", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } finally {
        setUploadPdfLoader(false);
      }
    }

    setAttachmentTitle("");
    setAttachmentFile("");
  };

  const handleDeletePrevAttachment = (selectedLink) => {
    const getDetails = JSON.parse(
      localStorage.getItem(`queryDetailsData_${queryId}`)
    );
    const updatedItem = getDetails["attachmentDetails"].filter(
      (deletedAttachment) => deletedAttachment.attachmentLink !== selectedLink
    );

    getDetails["attachmentDetails"] = updatedItem;
    localStorage.setItem(
      `queryDetailsData_${queryId}`,
      JSON.stringify(getDetails)
    );

    setPrevUploadedAttachments(
      prevuploadedAttachments.filter(
        (prevuploadedAttachment) =>
          prevuploadedAttachment.attachmentLink !== selectedLink
      )
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(storedItemsChecklist);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setStoredItemsChecklist(items);

    let newArray = [];
    items.map((item) => {
      newArray.push(item);
    });

    localStorage.setItem("selectedItems", JSON.stringify(newArray));
    const changeRows = JSON.parse(
      localStorage.getItem(`queryDetailsData_${queryId}`)
    );
    changeRows.itemDetails = newArray;
    localStorage.setItem(
      `queryDetailsData_${queryId}`,
      JSON.stringify(changeRows)
    );
  };

  const widthMap = {
    "Product Details": "180px",
    "Partner Name": "90px",
    Warranty: "90px",
    "Cost Per Unit(Excl GST)": "110px",
    "GST%": "45px",
    "Market Selling Price(Excl GST)": "80px",
    "Minimum Selling Price(Excl GST)": "80px",
    MRP: "80px",
    "Margin(in %)": "70px",
    "Selling Price(Excl GST)": "110px",
    "Selling Price(Incl GST)": "80px",
    Quantity: "80px",
    Amount: "80px",
    "Delete from Quote": "50px",
  };

  const handleQuoteConfirmationOpen = () => {
    if (storedItemsChecklist.some((item) => item.warranty_duration === "")) {
      toast.error("Select Warranty in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (
      storedItemsChecklist.some(
        (item) =>
          item.quantity === "" ||
          Number(item.quantity) < 1 ||
          !Number.isInteger(Number(item.quantity))
      )
    ) {
      toast.error("Enter Valid quantity", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (
      storedItemsChecklist.some(
        (item) => item.margin === "" || Number(item.margin) >= 100
      )
    ) {
      toast.error("Enter Valid Margin in all products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      storedItemsChecklist.some(
        (item) => item.spegst === "" || Number(item.spegst) < 0
      )
    ) {
      toast.error("Enter Valid Selling Price in all products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setQuoteConfirmation(true);
  };

  const handleQuoteConfirmationClose = () => {
    setQuoteConfirmation(false);
  };

  const handleSendToPricingOpen = (remark) => {
    setSendToPricingConfirmation(true);
    setRemark2(remark);
  };

  const handleSendToPricingClose = () => {
    setSendToPricingConfirmation(false);
  };

  const handleReopenToCategoryOpen = (remark) => {
    setReopenToCategoryConfirmation(true);
    setRemark(remark);
  };

  const handleReopenToCategoryClose = () => {
    setReopenToCategoryConfirmation(false);
  };

  const handleDivClick = () => {
    setIsChecked(!isChecked);
  };

  const [syncPopUp, setSyncPopUp] = useState(false);

  const handleSyncPopUpOpen = () => {
    setSyncPopUp(true);
  };

  const handleSyncPopUpClose = () => {
    setSyncPopUp(false);
  };

  const handleSaveDraft = async () => {
    try {
      setIsloading(true);
      const response = await fetch(`/quotegen/saveDraft/${queryId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          queryDetailsData: queryDetailsData,
          storedItemsChecklist: storedItemsChecklist,
          paymentTerms: paymentrows,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: prevuploadedAttachments,
          isChecked: isChecked,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success) {
        console.log("success");
        setIsloading(false);
        toast.success(`Draft saved`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Error Saving Draft", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error("Error Saving Draft", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsloading(false);
    }
  };

  const handleSync = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`/quotegen/syncDraft/${queryId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const { draft } = await response.json();

      setStoredItemsChecklist(draft.itemDetails);
      setDiscountInAmount(draft.discountInRupees);
      setDiscountInPercent(draft.discountInPercent);
      setPrevUploadedAttachments(draft.attachmentDetails);
      setDiscountedTotalAmount(draft.discountedAmount);
      setAmount(draft.totalAmount);
      setPaymentRows(draft.paymentTerms);
      setIsChecked(draft.isPaymentTable);
      setIsloading(false);
      toast.success(`Query details synced`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error during sync:", error);
    } finally {
      handleSyncPopUpClose();
      setIsloading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${queryId} - ${queryDetailsData.establishmentname}`}</title>
          </Helmet>
          <Button
            variant="contained"
            size="large"
            sx={{
              marginLeft: "1%",
              flexBasis: "15%",
              height: "50px",
              background: "#489A34",
              "&:hover": {
                background: "#327B28",
              },
              position: "absolute",
              right: "12%",
              top: "0px",
            }}
            onClick={() => handleOpenImportQuotationPopUp()}
          >
            Import
          </Button>

          <Button
            variant="contained"
            size="large"
            sx={{
              marginLeft: "1%",
              flexBasis: "15%",
              height: "50px",
              background: "#489A34",
              "&:hover": {
                background: "#327B28",
              },
              position: "absolute",
              right: "5%",
              top: "0px",
            }}
            onClick={handleSyncPopUpOpen}
          >
            Sync
          </Button>

          <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#5390c2" }}>
                  <TableCell />
                  {tableHeader.map((tableH) => (
                    <TableCell
                      key={tableH}
                      align="left"
                      sx={{
                        color: "#ffffff",
                        fontFamily: "Inter-Bold, Helvetica",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      {tableH}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {queryDetailsData && (
                  <Row
                    key={queryDetailsData._id}
                    row={queryDetailsData}
                    handleValueEditedOfQuery={(key, value) =>
                      handleValueEditedOfQuery(key, value)
                    }
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} className="custom-scrollbar">
            <Table>
              <TableHead>
                {/* 5390c2 */}
                <TableRow sx={{ backgroundColor: "#5390c2" }}>
                  <TableCell sx={{ width: "35px", minWidth: "35px" }} />
                  {filteredHeaders.map((priceL) => (
                    <TableCell
                      key={priceL}
                      align="center"
                      sx={{
                        color: "#ffffff",
                        fontFamily: "Inter-Bold, Helvetica",
                        fontSize: "15px",
                        fontWeight: "700",
                        width: widthMap[priceL],
                        minWidth: widthMap[priceL],
                      }}
                    >
                      {priceL}
                    </TableCell>
                  ))}
                  <TableCell
                    style={{
                      padding: "1px",
                    }}
                  />
                </TableRow>
              </TableHead>
            </Table>

            <Table aria-label="collapsible table">
              <TableBody>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {storedItemsChecklist &&
                          storedItemsChecklist.map((row, index) => (
                            <RowProduct
                              key={row._id}
                              open={openRow === row.sku}
                              onRowClick={() => handleRowClick(row.sku)}
                              row={row}
                              handleProductDelete={() =>
                                handleProductDelete(row)
                              }
                              handleValueEdited={(key, value) =>
                                handleValueEdited(row, key, value)
                              }
                              // handleChangeVendor={() => handleChangeVendor(row.sku)}
                              vendorsList={vendorsList}
                              warrantyDurationOptions={warrantyDurationOptions}
                              index={index}
                              setStoredItemsChecklist={setStoredItemsChecklist}
                              widthMap={widthMap}
                              queryId={queryId}
                            />
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </TableBody>
            </Table>

            <Table>
              <TableBody>
                <TableRow style={{ background: "#EAEAEA" }}>
                  <TableCell
                    sx={{ width: "35px", minWidth: "35px" }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      width: widthMap["Product Details"],
                      minWidth: widthMap["Product Details"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <strong>Total :</strong>
                    </div>
                  </TableCell>
                  {user && user.role !== "sales" && (
                    <TableCell
                      sx={{
                        width: widthMap["Partner Name"],
                        minWidth: widthMap["Partner Name"],
                      }}
                    ></TableCell>
                  )}
                  {user &&
                    (user.role === "category" || user.role === "sales") && (
                      <TableCell
                        sx={{
                          width: widthMap["Warranty"],
                          minWidth: widthMap["Warranty"],
                        }}
                      ></TableCell>
                    )}

                  {user && user.role !== "sales" && (
                    <TableCell
                      sx={{
                        width: widthMap["Cost Per Unit(Excl GST)"],
                        minWidth: widthMap["Cost Per Unit(Excl GST)"],
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {totalCostPrice > 100
                          ? parseFloat(totalCostPrice).toFixed(0)
                          : totalCostPrice < 100 && totalCostPrice >= 10
                          ? parseFloat(totalCostPrice).toFixed(1)
                          : parseFloat(totalCostPrice).toFixed(2)}
                      </div>
                    </TableCell>
                  )}

                  <TableCell
                    sx={{ width: widthMap["GST%"], minWidth: widthMap["GST%"] }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      width: widthMap["Market Selling Price(Excl GST)"],
                      minWidth: widthMap["Market Selling Price(Excl GST)"],
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      width: widthMap["Minimum Selling Price(Excl GST)"],
                      minWidth: widthMap["Minimum Selling Price(Excl GST)"],
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{ width: widthMap["MRP"], minWidth: widthMap["MRP"] }}
                  ></TableCell>

                  {user && user.role !== "sales" && (
                    <TableCell
                      sx={{
                        width: widthMap["Margin(in %)"],
                        minWidth: widthMap["Margin(in %)"],
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {totalspegstPrice &&
                          totalspegstPrice !== "" &&
                          (
                            ((totalspegstPrice - totalCostPrice) * 100) /
                            totalspegstPrice
                          ).toFixed(2)}
                        %
                      </div>
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      width: widthMap["Selling Price(Excl GST)"],
                      minWidth: widthMap["Selling Price(Excl GST)"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {totalspegstPrice > 100
                        ? parseFloat(totalspegstPrice).toFixed(0)
                        : totalspegstPrice < 100 && totalspegstPrice >= 10
                        ? parseFloat(totalspegstPrice).toFixed(1)
                        : parseFloat(totalspegstPrice).toFixed(2)}
                    </div>
                  </TableCell>

                  <TableCell
                    sx={{
                      width: widthMap["Selling Price(Incl GST)"],
                      minWidth: widthMap["Selling Price(Incl GST)"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {totalspigstPrice > 100
                        ? parseFloat(totalspigstPrice).toFixed(0)
                        : totalspigstPrice < 100 && totalspigstPrice >= 10
                        ? parseFloat(totalspigstPrice).toFixed(1)
                        : parseFloat(totalspigstPrice).toFixed(2)}
                    </div>
                  </TableCell>

                  <TableCell
                    sx={{
                      width: widthMap["Quantity"],
                      minWidth: widthMap["Quantity"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {totalquantity}
                    </div>
                  </TableCell>

                  <TableCell
                    sx={{
                      width: widthMap["Amount"],
                      minWidth: widthMap["Amount"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {amount > 100
                        ? parseFloat(amount).toFixed(0)
                        : amount < 100 && amount >= 10
                        ? parseFloat(amount).toFixed(1)
                        : parseFloat(amount).toFixed(2)}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: widthMap["Delete from Quote"],
                      minWidth: widthMap["Delete from Quote"],
                    }}
                  ></TableCell>
                  <TableCell style={{ padding: "1px" }}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {user && (user.role === "superuser" || user.role === "pricing") && (
            <div className="discount-payment-container">
              <div>
                <>
                  <div
                    style={{ fontSize: "14px" }}
                    onClick={() => handleDivClick()}
                  >
                    Include Payment Terms
                    <Checkbox defaultChecked={isChecked} checked={isChecked} />
                  </div>
                  <PaymentTermsTable
                    totalamount={Math.round(discountedTotalAmount)}
                    rows={paymentrows}
                    setRows={setPaymentRows}
                    paymentTermsCondition={paymentTermsCondition}
                    setPaymentTermsCondition={setPaymentTermsCondition}
                  />
                </>
              </div>
              <Table sx={{ width: "fit-content", height: "15vh" }}>
                <TableHead sx={{ background: "#5B7B99" }}>
                  <TableRow
                    sx={{
                      textAlign: "center",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableCell
                      sx={{
                        fontSize: "17px",
                        fontFamily: "Inter",
                        fontWeight: "700",
                        color: "white",
                        borderRadius: "7px",
                      }}
                    >
                      Discount
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    paddingTop: "10px",
                    background: "#EBEBEB",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridTemplateRows: "1fr 1fr",
                    borderBottomLeftRadius: "7px",
                    borderBottomRightRadius: "7px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "auto",
                      alignItems: "flex-start",
                      gap: "8px",
                      paddingLeft: "10% ",
                      paddingTop: "2%",
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      Discount (in %)
                    </label>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={Math.round(discountInPercent * 100) / 100}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <IconButton
                      //         onClick={() => setSelectedDiscountType("percent")}
                      //       >
                      //         <EditIcon />
                      //       </IconButton>
                      //     </InputAdornment>
                      //   ),
                      // }}
                      onClick={() => setSelectedDiscountType("percent")}
                      onChange={(e) => {
                        const input = e.target.value;
                        // Remove any non-numeric and non-decimal characters
                        const sanitizedInput = input.replace(/[^0-9.]/g, "");
                        // Update state only if the input is numeric or empty
                        if (!isNaN(sanitizedInput)) {
                          setDiscountInPercent(sanitizedInput);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          handleChangeDiscountAmount("percent");
                          setSelectedDiscountType("None");
                        }
                      }}
                      onBlur={() => {
                        if (selectedDiscountType === "percent") {
                          handleChangeDiscountAmount("percent");
                          setSelectedDiscountType("None");
                        }
                      }}
                      // onKeyDown={(e) =>
                      //   e.key === "Enter"
                      //     ? (handleChangeDiscountAmount("percent"),
                      //       setSelectedDiscountType("None"))
                      //     : null
                      // }
                      // disabled={selectedDiscountType !== "percent"}
                      sx={{
                        flex: "1",
                        background: "white",
                        border: "0.5px solid #CFCFCF",
                        borderRadius: "3px",
                        width: "90%",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "auto",
                      alignItems: "flex-start",
                      gap: "8px",
                      paddingLeft: "5% ",
                      paddingTop: "2%",
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      Discount in Amount(INR)
                    </label>
                    <TextField
                      fullWidth
                      // label={"Discount in Amount "}
                      variant="standard"
                      value={Math.round(discountInAmount)}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <IconButton
                      //         onClick={() => setSelectedDiscountType("amount")}
                      //       >
                      //         <EditIcon />
                      //       </IconButton>
                      //     </InputAdornment>
                      //   ),
                      // }}
                      onClick={() => setSelectedDiscountType("amount")}
                      onChange={(e) => {
                        const input = e.target.value;
                        // Remove any non-numeric and non-decimal characters
                        const sanitizedInput = input.replace(/[^0-9.]/g, "");
                        // Update state only if the input is numeric or empty
                        if (!isNaN(sanitizedInput)) {
                          // Check if input is a valid number
                          setDiscountInAmount(sanitizedInput);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          handleChangeDiscountAmount("amount");
                          setSelectedDiscountType("None");
                        }
                      }}
                      onBlur={() => {
                        if (selectedDiscountType === "amount") {
                          handleChangeDiscountAmount("amount");
                          setSelectedDiscountType("None");
                        }
                      }}
                      // disabled={selectedDiscountType !== "amount"}
                      sx={{
                        flex: "1",
                        height: "100%",
                        background: "white",
                        border: "0.5px solid #CFCFCF",
                        borderRadius: "3px",
                        width: "80%",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: "200%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Final Amount Inc. GST:
                    </p>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "white",
                        border: "0.5px solid #CFCFCF",
                        borderRadius: "3px",
                        width: "38%",
                        height: "33px",
                        textAlign: "center",
                      }}
                    >
                      {Math.round(discountedTotalAmount)}
                    </p>
                  </div>
                </TableBody>
                <div>
                  <br />
      <TableContainer component={Paper}>
        <Table>
        <TableHead style={{ background: "#5B7B99" }}>
          <TableRow>
              <TableCell style={{color: "white", fontWeight: "700", fontSize:"16px"}}>Enable Delivery Charges </TableCell>
              <TableCell>
                <Checkbox style={{color: "white"}}
                  checked={isDelivery}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'Enable delivery charges' }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{background:"#EBEBEB"}}>
              <TableCell>
              Enter delivery charges if applicable
              </TableCell>
              <TableCell>
              <TextField
                  type="number"
                  label="Delivery Charges"
                  value={deliveryCharges}
                  onChange={handleInputChange}
                  disabled={!isDelivery}
                /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>

              </Table>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "15px",
              width: "fit-content",
            }}
          >
            <h4 className="upload-file-heading">Upload File</h4>
            <div
              style={{
                borderRadius: "7px",
                width: "fit-content",
                minWidth: "30vw",
                padding: "10px 5px",
                border: "1px dashed #464C5C",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className="attachfilecontainer">
                <input
                  type="text"
                  value={attachmentTitle}
                  className="input-file"
                  placeholder="Title*"
                  required
                  onChange={(e) => setAttachmentTitle(e.target.value)}
                />

                <div
                  className="attachfilecontainerBtns"
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <input
                    type="file"
                    // value={attachmentFile}
                    className="choose-file"
                    id="choose-file-attachment"
                    accept="application/pdf"
                    required
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file.size <= 10 * 1024 * 1024) {
                        setAttachmentFile(file);
                      } else {
                        alert("File size exceeds 10MB limit.");
                        e.target.value = null;
                      }
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    style={{
                      width: "80%",
                    }}
                  />

                  <button
                    className="add-file-btn"
                    onClick={handleSubmitAttachment}
                    disabled={attachmentTitle === "" || attachmentFile === ""}
                    style={{
                      alignItems: "right",
                      justifyContent: "right",
                      width: "20%",
                      background:
                        attachmentTitle === "" || attachmentFile === ""
                          ? "gray"
                          : "#489A34",
                      cursor:
                        attachmentTitle === "" || attachmentFile === ""
                          ? "not-allowed"
                          : "pointer",
                      color: "white",
                      marginRight: "6px",
                    }}
                  >
                    Add
                  </button>
                  {/* <button
                    className="add-file-btn"
                    disabled={attachmentTitle === "" && attachmentFile === ""}
                    style={{
                      alignItems: "right",
                      justifyContent: "right",
                      width: "20%",
                      background:
                        attachmentTitle === "" && attachmentFile === ""
                          ? "gray"
                          : "#A80000",
                      cursor:
                        attachmentTitle === "" && attachmentFile === ""
                          ? "not-allowed"
                          : "pointer",
                      color: "white",
                      marginRight: "6px",
                    }}
                    onClick={() => {
                      setAttachmentFile("");
                      setAttachmentTitle("");
                      const chooseFileAttachment = document.getElementById(
                        "choose-file-attachment"
                      );
                      chooseFileAttachment.value = null;
                    }}
                  >
                    Clear
                  </button> */}
                </div>
              </div>
              {/* <div className="attachfilecontainer">
                <input
                  type="text"
                  value={attachmentTitle}
                  className="input-file"
                  placeholder="Title*"
                  required
                  onChange={(e) => setAttachmentTitle(e.target.value)}
                />

                <input
                  type="file"
                  // value={attachmentFile}
                  className="choose-file"
                  accept="application/pdf"
                  id="choose-file-attachment"
                  required
                  onChange={(e) => {
                    const file = e?.target?.files[0];
                    if (file.size <= 5 * 1024 * 1024) {
                      setAttachmentFile(file);
                    } else {
                      alert("File size exceeds 5MB limit.");
                      e.target.value = null;
                    }
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />

                <button
                  className="add-file-btn"
                  onClick={handleSubmitAttachment}
                >
                  Add
                </button>
              </div> */}

              <Box sx={{ width: "100%" }}>
                {uploadPdfLoader && <LinearProgress />}
              </Box>
              <p
                style={{
                  display: uploadedAttachments ? "inline" : "none",
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Inter",
                  color: "black",
                }}
              >
                Uploaded Files
              </p>
              {prevuploadedAttachments.length === 0 ? (
                <div></div>
              ) : (
                prevuploadedAttachments.map(
                  (prevuploadedAttachments, index) => (
                    <div key={index} className="prev-uploaded-files">
                      <p style={{ textAlign: "left", color: "#0081DF" }}>
                        {" "}
                        <strong>Title:</strong>{" "}
                        <a
                          href={prevuploadedAttachments.attachmentLink}
                          style={{ color: "#0081DF", textDecoration: "none" }}
                        >
                          {prevuploadedAttachments.attachmentTitle}
                        </a>
                      </p>
                      {/* <p style={{ textAlign: "left", color: "#0081DF" }}>
                        {" "}
                        <strong>File:</strong>{" "}
                        <a
                          href={prevuploadedAttachments.attachmentLink}
                          style={{ color: "#0081DF", textDecoration: "none" }}
                        >
                          Link {index + 1}
                        </a>
                      </p> */}
                      <Delete
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "3%",
                        }}
                        onClick={() =>
                          handleDeletePrevAttachment(
                            prevuploadedAttachments.attachmentLink
                          )
                        }
                      />
                    </div>
                  )
                )
              )}
            </div>
          </div>

          <div
            style={{
              marginBottom: "15px",
              width: "98%",
            }}
          >
            <Button
              onClick={handleSaveDraft}
              variant="contained"
              sx={{ marginRight: "12px" }}
            >
              Save Draft
            </Button>
          </div>

          {user && (user.role === "superuser" || user.role === "pricing") && (
            <div>
              {/* <div
                style={{ marginTop: "100px", width: "97%", fontSize: "14px" }}
              >
                <Checkbox defaultChecked />
                Also Sent to Client
              </div> */}
              <div style={{ width: "98%" }}>
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px" }}
                  onClick={handleOpenRemarkPopUp}
                >
                  ReOpen To Category
                </Button>
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  endIcon={<VisibilityIcon />}
                  onClick={() => {
                    handleopenpreview();
                  }}
                  disabled={
                    (isChecked && !paymentTermsCondition) ||
                    storedItemsChecklist.length === 0
                  }
                >
                  Preview Quote
                </Button>
                {/* <Button
                  variant="contained"
                  style={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  onClick={handleQuoteConfirmationOpen}
                  disabled={
                    !paymentTermsCondition || storedItemsChecklist.length === 0
                  }
                >
                  Generate Quote
                </Button> */}
              </div>

              <div>
                {reopenToCategoryConfirmation && (
                  <ConfirmationPopUp
                    show={reopenToCategoryConfirmation}
                    onClose={handleReopenToCategoryClose}
                    onConfirm={() => handleSendToCategory(remark)}
                    close={handleReopenToCategoryClose}
                    confirm={() => handleSendToCategory(remark)}
                    title={"Do you like to Re-open the Quotation to Category?"}
                    message={"The Quotation will be sent to Category Team"}
                  />
                )}
              </div>

              <div>
                {quoteConfirmation && (
                  <ConfirmationPopUp
                    show={quoteConfirmation}
                    onClose={handleQuoteConfirmationClose}
                    onConfirm={createQuotation}
                    close={handleQuoteConfirmationClose}
                    confirm={createQuotation}
                    title={"Do you like to proceed with the Quotation?"}
                    message={
                      "The quotation will be generated and send to the salesperson on mail."
                    }
                  />
                )}
              </div>
            </div>
          )}
          {user && user.role === "category" && (
            <div>
              <div style={{ width: "98%" }}>
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px", backgroundColor: "gray" }}
                  onClick={() => setOpenCloseRemarkPopUp(true)}
                >
                  Cannot Procure
                </Button>
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px" }}
                  onClick={handleSentToPricingRemarkPopUp}
                  disabled={storedItemsChecklist.length === 0}
                >
                  Send to Pricing
                </Button>
              </div>
              <div>
                {sendToPricingConfirmation && (
                  <ConfirmationPopUp
                    show={sendToPricingConfirmation}
                    onClose={handleSendToPricingClose}
                    onConfirm={() => handleSendToPricing(remark2)}
                    close={handleSendToPricingClose}
                    confirm={() => handleSendToPricing(remark2)}
                    title={"Do you like to send the quotation for Pricing?"}
                    message={"The Quotation will be sent to Pricing Team"}
                  />
                )}
              </div>
            </div>
          )}
          {user && user.role === "sales" && (
            <div>
              <div style={{ width: "98%" }}>
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  endIcon={<VisibilityIcon />}
                  onClick={() => {
                    handleopenpreview();
                  }}
                  disabled={storedItemsChecklist.length === 0}
                >
                  Preview Quote
                </Button>
                {/* <Button
                  variant="contained"
                  style={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  onClick={handleQuoteConfirmationOpen}
                  disabled={
                    !paymentTermsCondition || storedItemsChecklist.length === 0
                  }
                >
                  Generate Quote
                </Button> */}
              </div>
              <div>
                {quoteConfirmation && (
                  <ConfirmationPopUp
                    show={quoteConfirmation}
                    onClose={handleQuoteConfirmationClose}
                    onConfirm={createQuotation}
                    close={handleQuoteConfirmationClose}
                    confirm={createQuotation}
                    title={"Do you like to proceed with the Quotation?"}
                    message={
                      "The quotation will be generated and send to the salesperson on mail."
                    }
                  />
                )}
              </div>
            </div>
          )}

          <div>
            {openremarkPopUp && (
              <RemarkPopUp
                open={openremarkPopUp}
                onClose={() => setOpenRemarkPopUp(false)}
                onSelect={handleReopenToCategoryOpen}
              />
            )}
          </div>

          <ToastContainer autoClose={1000} />
        </div>
      )}
      <div>
        {openCloseremarkPopUp && (
          <CloseRemarkPopUp
            open={openCloseremarkPopUp}
            onClose={() => setOpenCloseRemarkPopUp(false)}
            onSelect={handleCloseQuery}
          />
        )}
      </div>
      <div>
        {openSendToPricingremarkPopUp && (
          <SentToPricingRemarkPopUp
            open={openSendToPricingremarkPopUp}
            onClose={() => setOpenSendToPricingRemarkPopUp(false)}
            onSelect={handleSendToPricingOpen}
          />
        )}
      </div>
      <div>
        {/* here */}
        {importPopUp && (
          <PreviousQuotationImport
            open={importPopUp}
            onClose={() => setImportPopUp(false)}
            storedItemsChecklist={storedItemsChecklist}
            setStoredItemsChecklist={setStoredItemsChecklist}
          />
        )}
      </div>

      <div>
        {/* here */}
        {syncPopUp && (
          <ConfirmationPopUp
            show={syncPopUp}
            close={handleSyncPopUpClose}
            confirm={handleSync}
            title={"Are you sure you want to sync this with latest draft?"}
            message={"Your current local progress will be lost!"}
          />
        )}
      </div>

      <div>
        {quoteGeneratedPopup && (
          <QuoteGeneratedPopup
            show={quoteGeneratedPopup}
            close={handleCloseQuotePopup}
            link={quoteLink}
            poc={queryDetailsData.pocName}
          />
        )}
      </div>

      {showPrevDialog && (
        <PrevDialog
          open={showPrevDialog}
          onClose={() => setshowPrevDialog(false)}
          quotation={quotations}
          handleQuoteConfirmationOpen={handleQuoteConfirmationOpen}
          pricingWarmth={pricingWarmth}
          setPricingWarmth={setPricingWarmth}
          user={user}
        />
      )}
    </div>
  );
};

const RemarkPopUp = ({ open, onClose, onSelect }) => {
  const [remarks, setRemarks] = useState("");

  const handleSelectRemark = () => {
    onSelect(remarks);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          handleSelectRemark();
        },
        sx: {
          width: "700px",
          maxWidth: "2000px !important",
        },
      }}
      sx={{
        position: "absolute",
        left: "25%",
        width: "900px",
        maxWidth: "2000px !important",
      }}
    >
      <DialogTitle>Client Requirement Remarks</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="title"
          name="title"
          label="Remarks"
          type="text"
          fullWidth
          variant="standard"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const CloseRemarkPopUp = ({ open, onClose, onSelect }) => {
  const [remarks, setRemarks] = useState("");

  const handleSelectRemark = () => {
    onSelect(remarks);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          handleSelectRemark();
        },
        sx: {
          width: "700px",
          maxWidth: "2000px !important",
        },
      }}
      sx={{
        position: "absolute",
        left: "25%",
        width: "900px",
        maxWidth: "2000px !important",
      }}
    >
      <DialogTitle>Reason for Closing Query?</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="title"
          name="title"
          label="Remarks"
          type="text"
          fullWidth
          variant="standard"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const SentToPricingRemarkPopUp = ({ open, onClose, onSelect }) => {
  const [remarks, setRemarks] = useState("");

  const handleSelectRemark = () => {
    onSelect(remarks);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          handleSelectRemark();
        },
        sx: {
          width: "700px",
          maxWidth: "2000px !important",
        },
      }}
    >
      <DialogTitle>Remarks</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          name="title"
          label="Remarks"
          type="text"
          fullWidth
          variant="standard"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const Row = ({ row, handleValueEditedOfQuery }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const { user } = useContext(UserContext);
  const [editedValue, setEditedValue] = useState(`${row.pocName}`);
  const [openCell, setOpenCell] = useState(false);
  const [editedValue2, setEditedValue2] = useState(`${row.establishmentname}`);
  const [openCell2, setOpenCell2] = useState(false);
  const keysToShow = [
    { key: "quantity", title: "Quantity" },
    { key: "specification", title: "Specification" },
    { key: "establishmentType", title: "Establishment Type" },
    { key: "phonePoc", title: "Phone POC" },
    { key: "bedNo", title: "Bed Number" },
    { key: "reasonUrgent", title: "Reason for Urgency" },
    { key: "remarks", title: "Remarks" },
    { key: "leadSource", title: "Lead Source" },
    { key: "image1", title: "Image 1" },
    { key: "image2", title: "Image 2" },
    { key: "image3", title: "Image 3" },
    { key: "image4", title: "Image 4" },
    { key: "validproofoftargetprice", title: "Valid Proof of Target Price" },
    { key: "remarksForCategory", title: "Remarks For Category" },
    { key: "remarksForReopen", title: "Re-open Remarks(If re-opened)" },
  ];

  function convertUTCToIST(utcDateTimeString) {
    const utcDateTime = new Date(utcDateTimeString);
    const ISTDateTime = utcDateTime.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    return ISTDateTime;
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell></TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          style={{ fontWeight: 500 }}
          onClick={() => setOpen(true)}
        >
          {row.queryId}
        </TableCell>
        <TableCell
          align="left"
          style={{ fontWeight: 500 }}
          onClick={() => setOpen(true)}
        >
          {row.createdAt
            ? convertUTCToIST(row.createdAt)
            : "1/1/2023, 10:52:44 am"}
        </TableCell>
        <TableCell
          align="left"
          style={{
            maxWidth: 50,
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            fontWeight: 500,
            padding: "1px",
          }}
          onClick={() => setOpen(true)}
        >
          {row.email}
        </TableCell>
        <TableCell
          align="left"
          style={{
            maxWidth: 500,
            whiteSpace: "pre-wrap",
          }}
          onClick={() => setOpen(true)}
        >
          {row.productName}
        </TableCell>
        <TableCell
          align="left"
          // style={{ fontWeight: 500 }}
          onClick={() => setOpenCell2(true)}
          style={{ fontWeight: 500, color: "#0081df", cursor: "pointer" }}
        >
          {openCell2 &&
          user &&
          (user.role === "superuser" || user.role === "pricing") ? (
            <TextField
              variant="filled"
              value={editedValue2}
              style={{ width: "fit-content", height: "50px" }}
              inputProps={{
                style: { background: "white", fontSize: "0.875rem" },
              }}
              onChange={(e) => setEditedValue2(e.target.value)}
              onBlur={() => {
                handleValueEditedOfQuery("establishmentname", editedValue2);
                setOpenCell2(false);
              }}
              onKeyDown={(e) =>
                e.key === "Enter"
                  ? (handleValueEditedOfQuery(
                      "establishmentname",
                      editedValue2
                    ),
                    setOpenCell2(false))
                  : null
              }
            />
          ) : (
            editedValue2
          )}
        </TableCell>

        <TableCell
          align="left"
          style={{ fontWeight: 500, color: "#0081df", cursor: "pointer" }}
          onClick={() => setOpenCell(true)}
        >
          {openCell &&
          user &&
          (user.role === "superuser" || user.role === "pricing") ? (
            <TextField
              variant="filled"
              value={editedValue}
              style={{ width: "fit-content", height: "50px" }}
              inputProps={{
                style: { background: "white", fontSize: "0.875rem" },
              }}
              onChange={(e) => setEditedValue(e.target.value)}
              onBlur={() => {
                handleValueEditedOfQuery("pocName", editedValue);
                setOpenCell(false);
              }}
              onKeyDown={(e) =>
                e.key === "Enter"
                  ? (handleValueEditedOfQuery("pocName", editedValue),
                    setOpenCell(false))
                  : null
              }
            />
          ) : (
            editedValue
          )}
        </TableCell>
        <TableCell
          align="left"
          style={{ padding: "1px" }}
          onClick={() => setOpen(true)}
        >
          {row.urgentQuery}
        </TableCell>
        <TableCell
          align="left"
          style={{ padding: "1px" }}
          onClick={() => setOpen(true)}
        >
          {row.city}
        </TableCell>
        <TableCell
          align="left"
          style={{ padding: "1px" }}
          onClick={() => setOpen(true)}
        >
          {row.warmth}
        </TableCell>
        <TableCell
          align="left"
          style={{
            padding: "1px",
          }}
          onClick={() => setOpen(true)}
        >
          {row.status === "toBeShared" && "To Be Assigned"}
          {row.status === "pendingOnPricing" && "Pending on Pricing"}
          {row.status === "pendingOnCategory" && "Pending on Category"}
          {row.status === "alreadyShared" && "Already Shared"}
          {row.status === "cannotProcure" && "Closed Query"}
        </TableCell>
      </TableRow>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ width: "fit-content", marginLeft: "35%" }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#1D282D",
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "18px",
            color: "white",
          }}
        >
          <span
            style={{
              fontFamily: "Inter",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "19.36px",
              color: "white",
              marginRight: "4px",
            }}
          >
            Query ID :
          </span>

          {row.queryId}

          <Cross
            onClick={handleClose}
            style={{ position: "relative", left: "28.5%", cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableBody>
                {keysToShow.map((key) => (
                  <TableRow key={key.key}>
                    <TableCell
                      style={{
                        color: "#000000",
                        fontFamily: "Inter",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "18px",
                      }}
                    >
                      {key.title}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "14.52px",
                      }}
                    >
                      {row[key.key]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrevDialog = ({
  open,
  onClose,
  onSelect,
  quotation,
  handleQuoteConfirmationOpen,
  pricingWarmth,
  setPricingWarmth,
  user,
}) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 8000);
  }, []);
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "fixed" }}
          style={{ backgroundColor: "#438ea3" }}
        >
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
              <Typography>
                Please go through it carefully before generating the Quotation
              </Typography>
            </Typography>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: "65px",
            overflowY: "hidden",
          }}
        >
          <PDFViewer
            showToolbar={false}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <PDFView quotations={quotation} />
          </PDFViewer>
        </div>

        {user && user.role === "pricing" && (
  <select
    value={pricingWarmth}
    onChange={(e) => setPricingWarmth(e.target.value)}
    style={{
      marginRight: "10px",
      border: "1px solid #1976D2",
      padding: "8px",
      borderRadius: "3px",
      backgroundColor: "#1976D2",
      color: "white",
      fontWeight: "500",
      fontSize: "15px",
      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
      position: "absolute",
      bottom: "25px",
      right: "285px",
    }}
    title="Select the warmth level for this lead based on your interactions with the Sales Representatives . This indicates how likely the lead is to convert soon. Higher warmth levels suggest the lead is closer to converting.  Cold < Warm < Hot < Red Hot"
  >
    <option value="" disabled selected>
      Warmth
    </option>
    <option value="Cold">Cold</option>
    <option value="Warm">Warm</option>
    <option value="Hot">Hot</option>
    <option value="Red Hot">Red Hot</option>
  </select>
)}
<Button
  autoFocus
  color="inherit"
  onClick={() => {
    if (user && user.role === "pricing" && pricingWarmth === "") {
      toast.error("Please select pricing warmth", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    handleQuoteConfirmationOpen();
  }}
  style={{
    position: "absolute",
    bottom: "25px",
    right: "69px",
    fontWeight: "bold",
    width: "14%",
    backgroundColor: disabled ? "gray" : "#489A34",
    marginLeft: "43%",
    color: "white",
    cursor: "pointer",
  }}
  disabled={disabled}
  title="Click to generate a quote"
>
  Generate Quote
</Button>
      </Dialog>
    </React.Fragment>
  );
};

export default QueryDetails;
